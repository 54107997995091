<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div class="app-content-box bg-gray wrapper">
      <div class="container mt-4">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">nazwij swój plan czytelniczy</div>
          <div class="row align-items-center">
            <div class="col-12">
              <div class="input-container mb-0 input-wrapper">
                <input type="text" class="form-input w-100"
                :class="{'input-validation': isTitleValid}"
                placeholder="Mój plan czytelniczy" v-model="trainingPlanTitle"
                >
              </div>
            </div>
          </div>
          <div class="top-heading-medium text-center">ile książek chcesz przeczytać?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="booksAmountArray" @active-index="setBookAmount" />
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
                <VerticalSlider :options="books" />
            </div>
          </div>
        </div>
        <hr class="section-spacer section-spacer-black">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">w jakim czasie chcesz je przeczytać?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="progressAmountArray" @active-index="setProgressAmount" />
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
                <VerticalSlider :options="progressTimeArray" @active-index="setProgressTime" />
            </div>
          </div>
        </div>
          <hr class="section-spacer section-spacer-black">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">ile czasu chcesz poświęcić na czytanie podczas jednej sesji?</div>
          <div class="input-spinners normal ">
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-hour">
              <VerticalSlider :options="sessionHoursArray" @active-index="setSessionHours" />
              <div class="input-spinner-label">h</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="sessionMinutesArray" @active-index="setSessionMinutes" />
              <div class="input-spinner-label">min.</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
               <VerticalSlider :options="sessionTimesArray" @active-index="setSessionTimes" />
            </div>
          </div>
          </div>
        </div>
        <div>
          <button class="btn-black-full w-100 mt-5" @click="validInput()">Dalej</button>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import VerticalSlider from '../components/VerticalSlider'
export default {
  name: 'TrainingPlanBooks',
  components: {
    TopMenuSubheadline,
    VerticalSlider
  },
  data () {
    return {
      title: 'Edytuj plan książkowy',
      planTitle: '',
      url: '',
      isTitleValid: false,
      booksAmountArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      books: ['książek'],
      progressAmountArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      progressTimeArray: ['tygodni', 'miesięcy'],
      sessionHoursArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      sessionMinutesArray: ['00', 10, 20, 30, 40, 50],
      sessionTimesArray: ['dziennie', 'tygodniowo']
    }
  },
  methods: {
    submit () {
      const fullTimeUnit = this.timeWithTrainer === 'tygodni' ? 'week' : 'month'
      this.minPerSession = this.minPerSession === '00' ? 0 : this.minPerSession
      const unitPerSession = this.timePerSession === 'dziennie' ? 'day' : 'week'
      const allSessionTime = (this.hoursPerSession * 60) + this.minPerSession
      const getData = `name=${this.trainingPlanTitle}&type=books&full_time_unit=${fullTimeUnit}&full_time=${this.daysWithTrainer}&books_total=${this.booksPerSession}&books_time=${allSessionTime}&books_time_unit=${unitPerSession}`

      this.$https('/plans', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$router.push({ name: 'TrainingPlanSelectBooks', params: { plan_id: response.data.response.id } })
      })
        .catch(error => {
          console.log('', error)
        })
    },
    validInput () {
      let checkInput = this.trainingPlanTitle
      checkInput = checkInput.replace(/\s/g, '')
      if (checkInput.trim() === '') {
        this.isTitleValid = true
      } else {
        this.isTitleValid = false
        this.submit()
      }
    },
    setBookAmount (index) {
      this.booksPerSession = this.booksAmountArray[index]
    },
    setProgressAmount (index) {
      this.daysWithTrainer = this.progressAmountArray[index]
    },
    setProgressTime (index) {
      this.timeWithTrainer = this.progressTimeArray[index]
    },
    setSessionHours (index) {
      this.hoursPerSession = this.sessionHoursArray[index]
    },
    setSessionMinutes (index) {
      this.minPerSession = this.sessionMinutesArray[index]
    },
    setSessionTimes (index) {
      this.timePerSession = this.sessionTimesArray[index]
    }
  },
  created () {
    this.booksPerSession = 1
    this.daysWithTrainer = 1
    this.timeWithTrainer = 'tygodni'
    this.hoursPerSession = 0
    this.minPerSession = '00'
    this.timePerSession = 'dziennie'
  },
  computed: {
    trainingPlanTitle: {
      get () {
        return this.$store.state.trainingPlan.trainingPlanTitle
      },
      set (value) {
        this.$store.commit('updateTrainingPlanTitle', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .wrapper {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .input {
    &-wrapper {
      padding-bottom: 20px;
    }
    &-validation {
      border: 1px solid red;
    }
  }
</style>
